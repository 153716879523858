
*:before, *:after {
	box-sizing: border-box;
	transform-style: preserve-3d;
	position: absolute;
	content: "";
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  @keyframes gradient {
      0% {
          background-position: 0% 0%;
      }
      50% {
          background-position: 100% 100%;
      }
      100% {
          background-position: 0% 0%;
      }
  }
  
  .particles {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  .particles:before, .particles:after {
    width: 200vw;
    height: 130vh;
    left: -50vw;
    top: -50vh;
    content: "";
    border-radius: 100%;
    opacity: 0;
    transform: translateZ(-50vmin);
    background-image: repeating-conic-gradient(#FFF8 0%, transparent .0002%, transparent .075%, transparent .65%), 
      repeating-conic-gradient(#FFF 0%, transparent .0004%, transparent 0.05%, transparent .495%);
    animation: particlesMovement 5s ease-in 0s infinite;
    z-index: 1;
  }
  
  .particles:after {
    animation-name: particlesMovement2;
    animation-delay: 1s;
  }
  
  .particles + .particles + .particles::before {
      animation-delay: 2s;
  }
  
  .particles + .particles + .particles::after {
      animation-delay: 3s;
  }
  
  @keyframes particlesMovement {
    0% { 
      transform: translateZ(-50vmin); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
      opacity: 1; 
    }
    100% { 
      transform: translateZ(50vmin) rotate(15deg); 
      opacity: 0; 
    }
  }
  
  @keyframes particlesMovement2 {
    0% { 
      transform: translateZ(-50vmin) rotate(180deg); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
       opacity: 1; 
    }
    100% { 
      transform: translateZ(50vmin) rotate(195deg); 
      opacity: 0; 
    }
  }
  }

@media screen and (min-width: 1200px) and (max-width: 1919px) {

@keyframes gradient {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}

.particles {
	position: absolute;
	width: 100vw;
	height: 100vh;
  overflow: hidden;
}

.particles:before, .particles:after {
	width: 200vw;
  height: 200vh;
  left: -50vw;
  top: -50vh;
  content: "";
  border-radius: 100%;
  opacity: 0;
  transform: translateZ(-50vmin);
  background-image: repeating-conic-gradient(#FFF8 0%, transparent .0002%, transparent .075%, transparent .65%), 
    repeating-conic-gradient(#FFF 0%, transparent .0004%, transparent 0.05%, transparent .495%);
  animation: particlesMovement 5s ease-in 0s infinite;
  z-index: 1;
}

.particles:after {
	animation-name: particlesMovement2;
	animation-delay: 1s;
}

.particles + .particles + .particles::before {
    animation-delay: 2s;
}

.particles + .particles + .particles::after {
    animation-delay: 3s;
}

@keyframes particlesMovement {
  0% { 
    transform: translateZ(-50vmin); 
    opacity: 0; 
  }
	30%, 70%, 90% { 
    opacity: 1; 
  }
	100% { 
    transform: translateZ(50vmin) rotate(15deg); 
    opacity: 0; 
  }
}

@keyframes particlesMovement2 {
  0% { 
    transform: translateZ(-50vmin) rotate(180deg); 
    opacity: 0; 
  }
	30%, 70%, 90% { 
     opacity: 1; 
  }
	100% { 
    transform: translateZ(50vmin) rotate(195deg); 
    opacity: 0; 
  }
}
}

@media screen and (min-width: 1920px) {

  @keyframes gradient {
      0% {
          background-position: 0% 0%;
      }
      50% {
          background-position: 100% 100%;
      }
      100% {
          background-position: 0% 0%;
      }
  }
  
  .particles {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  .particles:before, .particles:after {
    width: 200vw;
    height: 130vh;
    left: -50vw;
    top: -50vh;
    content: "";
    border-radius: 100%;
    opacity: 0;
    transform: translateZ(-50vmin);
    background-image: repeating-conic-gradient(#FFF8 0%, transparent .0002%, transparent .075%, transparent .65%), 
      repeating-conic-gradient(#FFF 0%, transparent .0004%, transparent 0.05%, transparent .495%);
    animation: particlesMovement 5s ease-in 0s infinite;
    z-index: 1;
  }
  
  .particles:after {
    animation-name: particlesMovement2;
    animation-delay: 1s;
  }
  
  .particles + .particles + .particles::before {
      animation-delay: 2s;
  }
  
  .particles + .particles + .particles::after {
      animation-delay: 3s;
  }
  
  @keyframes particlesMovement {
    0% { 
      transform: translateZ(-50vmin); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
      opacity: 1; 
    }
    100% { 
      transform: translateZ(50vmin) rotate(15deg); 
      opacity: 0; 
    }
  }
  
  @keyframes particlesMovement2 {
    0% { 
      transform: translateZ(-50vmin) rotate(180deg); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
       opacity: 1; 
    }
    100% { 
      transform: translateZ(50vmin) rotate(195deg); 
      opacity: 0; 
    }
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {

  @keyframes gradient {
      0% {
          background-position: 0% 0%;
      }
      50% {
          background-position: 100% 100%;
      }
      100% {
          background-position: 0% 0%;
      }
  }
  
  .particles {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  .particles:before, .particles:after {
    width: 200vw;
    height: 130vh;
    left: -50vw;
    top: -50vh;
    content: "";
    border-radius: 100%;
    opacity: 0;
    transform: translateZ(-50vmin);
    background-image: repeating-conic-gradient(#FFF8 0%, transparent .0002%, transparent .075%, transparent .65%), 
      repeating-conic-gradient(#FFF 0%, transparent .0004%, transparent 0.05%, transparent .495%);
    animation: particlesMovement 5s ease-in 0s infinite;
    z-index: 1;
  }
  
  .particles:after {
    animation-name: particlesMovement2;
    animation-delay: 1s;
  }
  
  .particles + .particles + .particles::before {
      animation-delay: 2s;
  }
  
  .particles + .particles + .particles::after {
      animation-delay: 3s;
  }
  
  @keyframes particlesMovement {
    0% { 
      transform: translateZ(-50vmin); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
      opacity: 1; 
    }
    100% { 
      transform: translateZ(50vmin) rotate(15deg); 
      opacity: 0; 
    }
  }
  
  @keyframes particlesMovement2 {
    0% { 
      transform: translateZ(-50vmin) rotate(180deg); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
       opacity: 1; 
    }
    100% { 
      transform: translateZ(50vmin) rotate(195deg); 
      opacity: 0; 
    }
  }
}

@media screen and (min-width: 3840px) {

  @keyframes gradient {
      0% {
          background-position: 0% 0%;
      }
      50% {
          background-position: 100% 100%;
      }
      100% {
          background-position: 0% 0%;
      }
  }
  
  .particles {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  .particles:before, .particles:after {
    width: 200vw;
    height: 130vh;
    left: -50vw;
    top: -50vh;
    content: "";
    border-radius: 100%;
    opacity: 0;
    transform: translateZ(-50vmin);
    background-image: repeating-conic-gradient(#FFF8 0%, transparent .0002%, transparent .075%, transparent .65%), 
      repeating-conic-gradient(#FFF 0%, transparent .0004%, transparent 0.05%, transparent .495%);
    animation: particlesMovement 5s ease-in 0s infinite;
    z-index: 1;
  }
  
  .particles:after {
    animation-name: particlesMovement2;
    animation-delay: 1s;
  }
  
  .particles + .particles + .particles::before {
      animation-delay: 2s;
  }
  
  .particles + .particles + .particles::after {
      animation-delay: 3s;
  }
  
  @keyframes particlesMovement {
    0% { 
      transform: translateZ(-50vmin); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
      opacity: 1; 
    }
    100% { 
      transform: translateZ(50vmin) rotate(15deg); 
      opacity: 0; 
    }
  }
  
  @keyframes particlesMovement2 {
    0% { 
      transform: translateZ(-50vmin) rotate(180deg); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
       opacity: 1; 
    }
    100% { 
      transform: translateZ(50vmin) rotate(195deg); 
      opacity: 0; 
    }
  }
}